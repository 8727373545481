import React from 'react'
import PropTypes from 'prop-types'

// Utilities
//import kebabCase from 'lodash/kebabCase'

// Components
import { Helmet } from 'react-helmet'
import EventsNav from '../components/EventsNav'
import EventsListing from '../components/EventsListing'
import TemplateWrapper from '../components/Layout'

import { graphql } from 'gatsby'

const EventsPage = ({ data }) => {
  return (
    <TemplateWrapper>
      <div>
        <Helmet title="Events | NAVEL" />
        <div>
          <EventsNav items={data.allMdx.group} />

          {
            // <ul>
            //   {data.allMdx.group.map(event => (
            //     <li key={event.fieldValue}>
            //       <Link to={`/${kebabCase(event.fieldValue)}/`}>
            //         {event.fieldValue} ({event.totalCount})
            //       </Link>
            //     </li>
            //   ))}
            // </ul>
          }

          <EventsListing
            data={data.eventobjects}
            ongoing={data.ongoing}
            committee={data.committee}
          />
        </div>
      </div>
    </TemplateWrapper>
  )
}

/* EventsPage.propTypes = {
   data: PropTypes.shape({
     allMdx: PropTypes.shape({
       group: PropTypes.arrayOf(
         PropTypes.shape({
           fieldValue: PropTypes.string.isRequired,
           totalCount: PropTypes.number.isRequired,
         }).isRequired
       ),
     }),
     site: PropTypes.shape({
       siteMetadata: PropTypes.shape({
         title: PropTypes.string.isRequired,
       }),
     }),
   }),
 };*/

export default EventsPage

export const pageQuery = graphql`
  query ProgramsQuery {
    allMdx(limit: 2000) {
      group(field: frontmatter___events) {
        fieldValue
        totalCount
      }
    }
    eventobjects: allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          eventType: { ongoing: { ne: true }, assemblies: { ne: "page" } }
        }
        fileAbsolutePath: { regex: "/events/" }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            subtitle
            date
            customDate
            time
            showOnHomepage
            eventType {
              assemblies
              committeeProgram
            }
            galleryImages {
              publicURL
              childImageSharp {
                fixed(width: 780, quality: 70) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
            primaryImage {
              publicURL
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            teaser
          }
          excerpt
        }
      }
    }
    ongoing: allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          eventType: { ongoing: { eq: true }, committeeProgram: { ne: true } }
        }
        fileAbsolutePath: { regex: "/events/" }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            subtitle
            date
            eventType {
              assemblies
            }
            customDate
            time
            showOnHomepage
            primaryImage {
              publicURL
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            teaser
          }
          excerpt
        }
      }
    }
    committee: allMdx(
      filter: {
        frontmatter: { eventType: { committeeProgram: { eq: true } } }
        fileAbsolutePath: { regex: "/events/" }
      }
      sort: { fields: [frontmatter___date], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            subtitle
            date
            customDate
            time
            showOnHomepage
            primaryImage {
              publicURL
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            teaser
          }
          excerpt
        }
      }
    }
  }
`
